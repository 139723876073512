.tab--underline {
  --border-color: transparent;
  --color: var(--text-tab-body-inactive);

  border-bottom-color: var(--border-color);
  color: var(--color);
}

.tab--underline.tab--active {
  --border-color: var(--border-prominent);
  --color: var(--text-tab-body-active);
}

.tab--underline:hover {
  --color: var(--text-tab-body-hovered);
}

.tab--underline:focus {
  --color: var(--text-tab-body-pressed);
}
