@import url(./form.css);
@import url(./checkbox.css);

html select {
  color: initial;
}

.input-themed-placeholder input::placeholder {
  color: var(--text-input-placeholder);
}
