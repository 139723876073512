@tailwind components;

@layer components {
  .radio-check-fill {
    fill: currentColor;
  }

  .checkbox-label:focus-within [type='checkbox'],
  [type='checkbox']:focus-within {
    border-radius: 2px;
    outline-color: var(--background-confirmation);
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 1px;
  }

  [type='checkbox'] ~ .checkbox {
    --border-color: var(--border-subdued);
    @apply border-2;

    border-color: var(--border-color);
  }

  [type='checkbox']:checked ~ .checkbox {
    @apply border-0;

    background: var(--background-feedback-default);
  }

  [type='checkbox']:checked ~ .checkbox svg {
    opacity: 1;
  }

  [type='checkbox'] ~ .betslip-checkbox {
    border-width: 1.5px;
    border-color: var(--border-betslip-input-default);
  }
}
