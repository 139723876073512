.text-link-primary:disabled {
  color: var(--text-link-primary-disabled);
}

.text-link-primary:hover {
  color: var(--text-link-primary-hovered);
}

.text-link-primary:active {
  color: var(--text-link-primary-pressed);
}

.text-link-secondary:disabled {
  color: var(--text-link-secondary-disabled);
}

.text-link-secondary:hover {
  color: var(--text-link-secondary-hovered);
}

.text-link-secondary:active {
  color: var(--text-link-secondary-pressed);
}

.text-link-betslip:hover {
  color: var(--text-link-betslip-hovered);
}

.text-link-betslip:active {
  color: var(--text-link-betslip-pressed);
}

.text-link-betslip:disabled {
  color: var(--text-link-betslip-disabled);
}

.foreground-header-live {
  color: var(--foreground-header-live);
}

.foreground-header-red-zone {
  color: var(--foreground-header-red-zone);
}

.foreground-red-zone {
  color: var(--foreground-red-zone);
}

.text-cosmetic-gradient-stroke {
  text-shadow:
    1px 1px 2px var(--border-participant),
    -1px 1px 2px var(--border-participant),
    1px -1px 2px var(--border-participant),
    -1px -1px 2px var(--border-participant);
}
