progress.stat-tracker::-webkit-progress-bar {
  height: 0.25rem;
}

progress[value].stat-tracker::-webkit-progress-value,
.stat-tracker-tick_container {
  transition: all 0.7s ease-in-out;
}

.stat-tracker-tick {
  @apply h-3 w-2;
  @apply rounded;
  background-color: var(--graphic-secondary);
  border: 3px solid var(--background-offset);
}

progress.stat-tracker::-webkit-progress-value {
  background-color: var(--graphic-primary);
}

progress.stat-tracker.stat-tracker-settled-not-win::-webkit-progress-value {
  background-color: var(--graphic-subdued-primary);
}

progress.stat-tracker.stat-tracker-settled-win::-webkit-progress-value {
  background-color: var(--graphic-success);
}
