@tailwind components;

button {
  user-select: none;
}

button.button {
  border: 2px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

button:disabled {
  cursor: point;
  pointer-events: none;
}

/* TODO: AODS-689 */
/* button:focus {
  outline-width: 2px;
  outline-offset: 1px;
  outline-style: solid;
} */

/* PRIMARY & BETSLIP
 * ---------------------------------------------------- */

button.button-betslip-primary,
button.button-primary {
  background-color: var(--background-button-primary-default);
  color: var(--text-button-primary-default);
}

button.button-betslip-primary:disabled {
  background-color: var(--background-button-betslip-disabled);
  color: var(--text-button-betslip-disabled);
}

button.button-primary:disabled {
  background-color: var(--background-button-primary-disabled);
  color: var(--text-button-primary-disabled);
}

button.button-betslip-primary:hover,
button.button-primary:hover {
  background-color: var(--background-button-primary-hovered);
  color: var(--text-button-primary-hovered);
}

button.button-betslip-primary:active,
button.button-primary:active {
  background-color: var(--background-button-primary-pressed);
  color: var(--text-button-primary-pressed);
}

button.button-betslip-primary:focus,
button.button-primary:focus {
  border-color: var(--border-interactive-focused);
  outline-color: var(--border-interactive-focused);
}

button.button-primary svg {
  color: var(--icon-button-primary-default);
}

button.button-primary:disabled svg {
  color: var(--icon-button-primary-disabled);
}

button.button-primary:hover svg {
  color: var(--icon-button-primary-hovered);
}

button.button-primary:active svg {
  color: var(--icon-button-primary-pressed);
}

/* SECONDARY
 * ---------------------------------------------------- */

button.button-secondary {
  background-color: var(--background-button-secondary-default);
  color: var(--text-button-secondary-default);
}

button.button-secondary:disabled {
  background-color: var(--background-button-secondary-disabled);
  color: var(--text-button-secondary-disabled);
}

button.button-secondary:hover {
  background-color: var(--background-button-secondary-hovered);
  color: var(--text-button-secondary-hovered);
}

button.button-secondary:active {
  background-color: var(--background-button-secondary-pressed);
  color: var(--text-button-secondary-pressed);
}

button.button-secondary:focus {
  border-color: var(--border-interactive-focused);
  outline-color: var(--border-interactive-focused);
}

button.button-secondary svg {
  color: var(--icon-button-secondary-default);
}

button.button-secondary:disabled svg {
  color: var(--icon-button-secondary-disabled);
}

button.button-secondary:hover svg {
  color: var(--icon-button-secondary-hovered);
}

button.button-secondary:active svg {
  color: var(--icon-button-secondary-pressed);
}

/* PROGRESS SUCCESS
 * ---------------------------------------------------- */

button.button-progress-success {
  background-color: var(--background-button-progress-success);
  color: var(--text-button-progress-success);
}

button.button-progress-success:disabled {
  background-color: var(--background-button-progress-temporary-disabled);
  color: var(--text-button-progress-temporary-disabled);
}

button.button-progress-success:focus {
  border-color: var(--border-interactive-focused);
}

/* OUTLINE
 * ---------------------------------------------------- */

button.button-outlined {
  border-color: var(--border-interactive-default);
  color: var(--text-button-outlined-default);
}

button.button-outlined:disabled {
  border-color: var(--border-interactive-disabled);
  color: var(--text-button-outlined-disabled);
}

button.button-outlined:hover {
  border-color: var(--border-interactive-hovered);
  color: var(--text-button-outlined-hovered);
}

button.button-outlined:active {
  border-color: var(--border-interactive-pressed);
  color: var(--text-button-outlined-pressed);
}

/* JACKPOT
 * ---------------------------------------------------- */

button.button-jackpot {
  border-color: var(--border-casino-jackpot-interactive-default);
  color: var(--text-button-jackpot-default);
}

button.button-jackpot:disabled {
  border-color: var(--border-casino-jackpot-interactive-disabled);
  color: var(--text-button-jackpot-disabled);
}

button.button-jackpot:hover {
  border-color: var(--border-casino-jackpot-interactive-hovered);
  color: var(--text-button-jackpot-hovered);
}

button.button-jackpot:active {
  border-color: var(--border-casino-jackpot-interactive-pressed);
  color: var(--text-button-jackpot-pressed);
}

/* BET SELECTOR DESELECTED
 * ---------------------------------------------------- */

button.button-bet-selector-deselected {
  background-color: var(--background-control-deselected-default);
  color: var(--text-selector-deselected);
}

button.button-bet-selector-deselected:disabled {
  background-color: var(--background-control-deselected-disabled);
  color: var(--text-selector-deselected);
}

button.button-bet-selector-deselected:hover {
  background-color: var(--background-control-deselected-hovered);
  color: var(--text-selector-deselected);
}

button.button-bet-selector-deselected:active {
  background-color: var(--background-control-deselected-hovered);
  color: var(--text-selector-deselected);
}

/* BET SELECTOR SELECTED
 * ---------------------------------------------------- */

button.button-bet-selector-selected {
  background-color: var(--background-control-selected-default);
  color: var(--text-selector-selected);
}

button.button-bet-selector-selected:disabled {
  background-color: var(--background-control-deselected-disabled);
  color: var(--text-selector-selected);
}

button.button-bet-selector-selected:hover {
  background-color: var(--background-control-selected-hovered);
  color: var(--text-selector-selected);
}

button.button-bet-selector-selected:active {
  background-color: var(--background-control-selected-pressed);
  color: var(--text-selector-selected);
}

/* BET SELECTOR HIDDEN
 * ---------------------------------------------------- */

button.button-bet-selector-hidden {
  background-color: var(--background-control-deselected-disabled);
  color: var(--text-disabled);
}

button.text-link,
.text-link,
button.text-link-secondary,
.text-link-secondary {
  @apply p-1 font-medium outline-none;
  @apply hover:underline;
  @apply focus-visible:rounded focus-visible:outline-2;
}

/* TEXT LINK SECONDARY
 * ---------------------------------------------------- */

a.text-link-secondary,
button.text-link-secondary {
  color: var(--text-link-secondary-default);
}

a.text-link-secondary:disabled,
button.text-link-secondary:disabled {
  color: var(--text-link-secondary-disabled);
}

a.text-link-secondary:hover,
button.text-link-secondary:hover {
  color: var(--text-link-secondary-hovered);
  text-decoration: underline;
}

a.text-link-secondary:active,
button.text-link-secondary:active {
  color: var(--text-link-secondary-pressed);
}

button.btn-small,
.btn-small {
  padding: 6px 15px;
  @apply text-description;
}

/* PASSWORD SHOW BUTTON
 * ---------------------------------------------------- */

.text-button-alert {
  color: var(--text-button-alert);
}

/* BET SELECTOR
 * ---------------------------------------------------- */
.button-bet-selector-deselected:focus {
  background-color: var(--background-control-deselected-pressed);
}

.button-bet-selector-selected:focus {
  background-color: var(--background-control-selected-pressed);
}
