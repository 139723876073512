@tailwind components;

@layer components {
  .link {
    @apply p-1 text-body font-medium outline-none;
    @apply hover:underline;
    @apply focus-visible:rounded focus-visible:outline-2 focus-visible:outline-blue-a11y;
  }

  .link-small {
    @apply text-description;
  }

  .link-typography-footnote {
    @apply text-white text-opacity-50;
  }

  .link-button {
    @apply inline-block;
    @apply w-full sm:w-auto;
    @apply rounded;
    @apply text-center font-bold text-white;
    @apply outline-none;
    @apply focus-visible:outline-2 focus-visible:outline-blue-a11y;
    @apply bg-blue hover:bg-blue-darker focus:bg-blue-darker;
  }

  .link-button-sm {
    @apply link-button;
    @apply px-4 py-2;
  }

  .link-button-lg {
    @apply link-button;
    @apply px-6 py-3;
  }
}
