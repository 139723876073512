@tailwind components;

@layer components {
  .input-field {
    @apply relative;

    --border-color: var(--border-input-default);
    --input-color: var(--text-input-primary);
    --label-color: var(--text-input-placeholder);
  }

  .input,
  .label {
    @apply transition-all duration-200 ease-in-out;
  }

  .dropdown-button,
  .input {
    @apply w-full rounded border bg-transparent p-2.5 outline-none duration-200;

    border-color: var(--border-color);
    color: var(--input-color);
  }

  .input {
    caret-color: var(--text-input-focused);
  }

  .input--caret-default .input {
    caret-color: initial;
  }

  .input:not(:placeholder-shown) + .label {
    --label-color: var(--text-input-secondary);
  }

  .label {
    @apply absolute left-2.5 top-2.5 z-[1] origin-0;

    color: var(--label-color);
  }

  .input-field--error,
  .input-field--error .input {
    --border-color: var(--border-danger);
    --label-color: var(--text-danger);
  }

  .dropdown,
  .input:focus + .label {
    --label-color: var(--text-input-secondary);
  }

  .dropdown-label,
  .input:not(:placeholder-shown) + .label,
  .input:focus + .label,
  .input-field--always-open .label {
    @apply -top-2 left-1.5 transform px-1;
    @apply pointer-events-none select-none leading-tight;

    /* TODO: https://thescore.atlassian.net/browse/AODS-1207 */
    /* .text-style-2xs-medium */
    font-family: var(--font-default);
    font-weight: var(--font-weight-medium);
    font-size: var(--text-size-2xs);
    letter-spacing: var(--letter-space-loose);
    line-height: var(--line-height-xs);
  }

  .betslip-input {
    color: var(--betslip-input-color, var(--text-input-betslip-primary));
    border: 1px solid var(--border-color, var(--border-betslip-input-default));
    @apply flex flex-col gap-1 rounded px-2 py-1 outline outline-2 outline-transparent;
  }

  .betslip-input input {
    @apply bg-inherit outline-none;
  }

  .betslip-input:has(input:placeholder-shown) {
    --betslip-input-color: var(--text-input-placeholder-betslip);
  }

  .betslip-input input::placeholder {
    color: var(--text-input-placeholder-betslip);
  }

  .betslip-input:focus-within {
    --border-color: transparent;
    @apply outline-[--border-betslip-interactive-focused];
  }

  .betslip-input.betslip-input--disabled {
    --betslip-input-color: var(--text-betslip-disabled);
    --border-color: var(--border-betslip-input-disabled);
  }

  .betslip-input.betslip-input--error {
    --border-color: transparent;
    @apply outline-[--border-danger];
  }

  .betslip-input svg {
    color: var(--icon-betslip-default);
  }

  div.betslip-selection-list > div:not(:first-child) {
    border-width: 1px 0 0 0;
    border-color: var(--border-betslip-default);
    border-style: solid;
  }

  .quick-betslip {
    background: var(--background-betslip-primary);
    color: var(--text-betslip-primary);
  }

  .quick-betslip .betslip-input:focus-within {
    outline-color: var(--border-input-focused);
  }

  .quick-betslip .betslip-input.betslip-input--disabled {
    color: var(--text-betslip-disabled);
  }

  .quick-betslip .draft-leg[data-error='true'] .draft-leg--description {
    color: var(--text-betslip-disabled);
  }
}

.dropdown--open,
.input-field:not(.dropdown) .input:focus-within,
.input-field--is-focused,
.input-field:focus,
.input:focus ~ .label {
  --border-color: var(--border-interactive-focused);
  --label-color: var(--text-input-focused);
}

.input-field .label {
  background-color: var(--background-color);
  color: var(--label-color);
}

.input-field .input {
  border: 1px solid;
  border-color: var(--border-color);
  color: var(--color);

  @apply rounded;
}

.input-field-search--secondary .input {
  --color: var(--text-input-search-primary);
  --border-color: transparent;
}

.input-field-search--secondary .input:focus {
  caret-color: var(--text-input-search-focused);
  --border-color: transparent !important;
}

.input-field-search--secondary .input::placeholder {
  --color: var(--text-input-search-placeholder);
}
