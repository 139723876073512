/*
* Contextual PR from web-monorepo: https://github.com/scorebet/web-monorepo/pull/504
* position: sticky cannot work if the ancestor divs have overflow: hidden
* because of this,  we need to overwrite with overflow: visible (overflow-x: hidden also breaks it for some reason)
* we need very powerful specificity because simplebar is a vanilla Javascript package using inline styling
*/
nav.flex.flex-1.overflow-hidden,
div.simplebar-mask,
div.simplebar-content-wrapper {
  overflow: visible !important;
}
