.slider-root {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  cursor: pointer;
}
.slider-root[data-orientation='horizontal'] {
  height: 24px;
}
.slider-root[data-orientation='vertical'] {
  flex-direction: column;
  width: 20px;
  height: 100px;
}

.slider-track {
  background-color: var(--background-feedback-empty);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
}
.slider-track[data-orientation='horizontal'] {
  height: 4px;
}
.slider-track[data-orientation='vertical'] {
  width: 4px;
}

.slider-range {
  position: absolute;
  background-color: var(--background-feedback-empty);
  border-radius: 9999px;
  height: 100%;
}

.slider-thumb {
  display: block;
  width: 24px;
  height: 24px;
  background-color: var(--background-button-primary-default);
  border-radius: 12px;
}
.slider-thumb:hover {
  background-color: var(--background-button-primary-default);
  cursor: grab;
}
.slider-thumb:active {
  background-color: var(--background-button-primary-default);
  cursor: grabbing;
}
