/* ICONS
 * ---------------------------------------------------- */

.icon-betslip-default {
  color: var(--icon-betslip-default);
}

.icon-betslip-disabled {
  color: var(--icon-betslip-disabled);
}

.icon-betslip-secondary {
  color: var(--icon-betslip-secondary);
}

.icon-button-floating {
  color: var(--icon-button-floating);
}

.icon-button-secondary {
  color: var(--icon-button-secondary);
}

.icon-casino-jackpot {
  color: var(--icon-casino-jackpot);
}

.icon-danger {
  color: var(--icon-danger);
}

.icon-header {
  color: var(--icon-header);
}

.icon-indicator {
  color: var(--icon-indicator);
}

.icon-live {
  color: var(--icon-live);
}

.icon-loading-spinner-primary {
  color: var(--icon-loading-spinner-primary);
}

.icon-loading-spinner-secondary {
  color: var(--icon-loading-spinner-secondary);
}

.icon-menu {
  color: var(--icon-menu);
}
.icon-menu-drawer {
  color: var(--icon-navigation-primary);
}

.icon-menu-primary {
  color: var(--icon-menu-primary);
}

.icon-menu-secondary {
  color: var(--icon-menu-secondary);
}

.icon-on-feedback {
  color: var(--icon-on-feedback);
}

.icon-on-overlay-primary {
  color: var(--icon-on-overlay-primary);
}

.icon-on-success {
  color: var(--icon-on-success);
}

.icon-penn-play-banner {
  color: var(--icon-penn-play-banner);
}

.icon-primary {
  color: var(--icon-primary);
}

.icon-secondary {
  color: var(--icon-secondary);
}

.icon-disabled {
  color: var(--icon-disabled);
}

.icon-danger {
  color: var(--icon-danger);
}

.icon-search {
  color: var(--icon-search);
}

.icon-subdued {
  color: var(--icon-subdued);
}

.icon-success {
  color: var(--icon-success);
}

.icon-tab-body-pressed {
  color: var(--icon-tab-body-pressed);
}

.icon-warning {
  color: var(--icon-warning);
}

/* GRAPHICS
 * ---------------------------------------------------- */

.graphic-subdued-primary {
  color: var(--graphic-subdued-primary);
}

.graphic-primary {
  color: var(--graphic-primary);
}

.graphic-subdued-secondary {
  color: var(--graphic-subdued-secondary);
}

.graphic-danger {
  color: var(--graphic-danger);
}
