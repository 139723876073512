@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'background.css';
@import 'button.css';
@import 'icon.css';
@import 'link.css';
@import 'form/index.css';
@import 'modal.css';
@import 'panel.css';
@import 'slider.css';
@import 'tab.css';
@import 'text.css';
@import 'progress.css';
@import 'font.css';
@import 'braintree.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@import '@scorebet/alley-oop-react/dist/index.css';
@import '@scorebet/components/styles.css';
@import 'simplebar.css';

:root {
  --header-height: 56px;
}

html,
body,
#__next {
  @apply h-full;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--background-navigation-default);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--background-navigation-selected);
  border-radius: 8px;
  border: 3px solid var(--background-header);
}

/* For Firefox */
@supports (scrollbar-color: auto) {
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--background-navigation-selected)
      var(--background-navigation-default);
  }
}
/* For Microsoft Edge */
.custom-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html {
  --background-color: var(--background-primary);
}

html,
body {
  background: var(--background-color);
}

body {
  color: var(--text-primary);
  font-family: 'Inter', sans-serif;
  font-feature-settings: 'cv05';
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

progress::-webkit-progress-bar {
  @apply h-2 rounded-full;
  @apply w-full;
  background-color: var(--background-feedback-empty);
}

progress::-webkit-progress-value {
  @apply rounded-full;
  background-color: var(--graphic-primary);
}

progress::-moz-progress-bar {
  @apply h-2 rounded-full;
  @apply w-full;
  background-color: var(--graphic-primary);
}

progress {
  @apply h-2 rounded-full;
  @apply w-full;
  background-color: var(--background-feedback-empty);
}

.betslip-rounded-underline {
  position: relative;
}

.betslip-rounded-underline::after {
  background-color: var(--border-betslip-prominent);
  content: '';
  display: block;

  @apply absolute bottom-0 left-0 h-1 w-full rounded;
}

.betslip-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.betslip-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--background-scrollbar);
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* For Firefox */
@supports (scrollbar-color: auto) {
  .betslip-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--background-scrollbar) transparent;
  }
}

.sports-menu-item:active {
  background-color: var(--background-menu-row-primary-active);
}

.sports-menu-item:focus {
  background-color: var(--background-menu-row-primary-pressed);
}

.sports-menu-item:hover {
  background-color: var(--background-menu-row-primary-hovered);
}

.bg-menu-row-primary:is(:active, :focus, :hover) {
  text-decoration: none;
}

.bg-menu-row-primary-active,
.bg-menu-row-primary:active {
  background-color: var(--background-menu-row-primary-active);
}

.bg-menu-row-primary:focus {
  background-color: var(--background-menu-row-primary-pressed);
}

.bg-menu-row-primary:hover {
  background-color: var(--background-menu-row-primary-hovered);
}

.bg-gradient-edge {
  --bg-gradient-direction: to left;

  background: linear-gradient(
    var(--bg-gradient-direction),
    var(--background-primary) var(--tw-gradient-from-position),
    transparent
  );
}

.bg-gradient-edge--left {
  --bg-gradient-direction: to right;
}

.bg-card-pressed {
  --background-color: var(--background-primary-pressed);

  background: var(--background-color);
}

.bg-offset:active {
  background: var(--background-offset);
}

.bg-betslip-primary:active {
  background: var(--background-betslip-primary);
}

.bg-header-betslip:active {
  background: var(--background-header-betslip);
}

.shadow-popover {
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.16),
    0px 2px 10px 0px rgba(0, 0, 0, 0.08);
}

.shadow-betslip {
  /* TODO: https://thescore.atlassian.net/browse/AODS-479 */
  box-shadow: 0px 1.5px 6px 0px rgba(18, 18, 19, 0.24);
}

.shadow-toast {
  /* TODO: https://thescore.atlassian.net/browse/AODS-479 */
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.3),
    0 -1px 8px 0 rgba(0, 0, 0, 0.15);
}

.icon-betslip {
  color: var(--icon-betslip-default);
}

.text-shadow-placeholder {
  text-shadow:
    -1px -1px 0px var(--text-placeholder-outline),
    1px 1px 0px var(--text-placeholder-outline),
    -1px 1px 0px var(--text-placeholder-outline),
    1px -1px 0px var(--text-placeholder-outline);
}

.app-logo--header-tsb,
.app-logo--footer-tsb {
  height: 1.5rem;
  width: unset;
}

.recommended-props-scroll-shadow {
  width: calc(100% - 0.75rem);
}

.recommended-props-content {
  content-visibility: auto;
}

@supports (-moz-appearance: none) {
  .recommended-props-scroll-shadow {
    width: 100%;
  }
  .recommended-props-content {
    padding-right: 0.75rem;
    scrollbar-width: thin;
    scrollbar-color: var(--background-scrollbar)
      var(--background-navigation-default);
  }
}

.recommended-props-content::-webkit-scrollbar {
  width: 6px;
  display: block;
}

.recommended-props-content::-webkit-scrollbar-corner {
  visibility: hidden;
}

.recommended-props-content::-webkit-scrollbar-thumb {
  background-color: var(--background-scrollbar);
  border-radius: 8px;
}

.app-logo--header-espnbet {
  max-height: 2rem;
  max-width: 7.5rem;
  width: auto;
}

.app-logo--header-wrapper-espnbet {
  width: 7.5rem;
}

[data-popper-reference-hidden='true'] {
  opacity: 0;
  pointer-events: none;
}

.bet-leg-parlay_inner-container:before {
  content: '';
  width: 1px;
  height: calc(100% - 0.75rem);
  background-color: var(--bet-indicator-unsettled);
  position: absolute;
  left: 0.33rem;
  top: 2rem;
}

.bet-leg-parlay_container .bet-leg-parlay_inner-container:last-child:before {
  display: none;
}

.experimental-fm-bet-leg-container .bet-leg-parlay_inner-container:before {
  left: 1.25rem;
  top: 2.4rem;
  height: calc(100% - 1.6rem);
  background-color: var(--border-default);
}

.fm-bet-leg-container .bet-leg-parlay_inner-container:before {
  top: 1.75rem;
}

iframe.default-height {
  height: calc(100vh - var(--header-height));
}

.min-h-dvh-fallback {
  min-height: 100vh;
  min-height: 100dvh;
}

.min-h-dvh-fallback-minus-header {
  min-height: calc(100vh - var(--header-height));
  min-height: calc(100dvh - var(--header-height));
}

.live-stream-drawer theoplayer-default-ui,
.live-stream-drawer theoplayer-ui {
  width: 100%;
  height: auto;
}

.live-stream-wrapper video {
  border-radius: 0.5rem;
}

.live-stream-drawer:hover .live-stream-close-button {
  visibility: visible;
}
