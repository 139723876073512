/* .text-style-xs-medium */
.text-detail {
  font-family: var(--font-default);
  font-weight: var(--font-weight-medium);
  font-size: var(--text-size-xs);
  letter-spacing: var(--letter-space-loose);
  line-height: var(--line-height-s);
}

/* .text-style-m-medium */
.text-body {
  font-family: var(--font-default);
  font-weight: var(--font-weight-medium);
  font-size: var(--text-size-m);
  letter-spacing: var(--letter-space-default);
  line-height: var(--line-height-m);
}

/* .text-style-xl-medium */
.text-headline-1 {
  font-family: var(--font-default);
  font-weight: var(--font-weight-medium);
  font-size: var(--text-size-xl);
  letter-spacing: var(--letter-space-tight);
  line-height: var(--line-height-l);
}

/* .text-style-3xl-bold */
/* .text-jumbo, */
.text-large-title,
.text-medium-title {
  font-family: var(--font-default);
  font-weight: var(--font-weight-bold);
  font-size: var(--text-size-3xl);
  letter-spacing: var(--letter-space-tightest);
  line-height: var(--line-height-2xl);
}
