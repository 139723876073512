.modal {
  --modal-padding: 1rem;
  --modal-background-color: var(--background-surface-overlay);

  padding: var(--modal-padding);
  background-color: var(--modal-background-color);
}

.modal.modal--quick-bets-mode {
  --modal-padding: 0rem;
  --modal-background-color: var(--background-primary);
}

.modal--quick-bets-mode .quick-bets-mode-header {
  --text-primary: var(--text-header-primary);
  --text-subdued-primary: var(--text-header-secondary);
  background: var(--background-header);
}

.modal.modal--see-all-lines {
  --modal-background-color: var(--background-card-primary);
}

.modal.modal--featured-bet {
  --modal-padding: 1rem 0rem 0rem;
}
