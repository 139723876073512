/*
* TODO: Replace these with actual color tokens
* https://thescore.slack.com/archives/C04N27HC2NQ/p1720548544584179
*/
:root {
  --background-success-8: color-mix(
    in srgb,
    var(--background-success),
    transparent 92%
  );
  --background-success-40: color-mix(
    in srgb,
    var(--background-success),
    transparent 60%
  );
}

.before\:\:bg-icon-indicator::before {
  background-color: var(--icon-indicator);
}

.bg-icon-indicator {
  background-color: var(--icon-indicator);
}

.bg-casino-spotlight-card {
  background-image: linear-gradient(
    275deg,
    var(--background-card-offset) 17.7%,
    var(--background-success) 100%
  );
}

.bg-hover-highlight-prominent {
  background-color: var(--border-prominent);
}

/* BETSLIP EVENT HEADER
 * ---------------------------------------------------- */

.bg-betslip-event-header:hover {
  background-color: var(--background-betslip-primary-hovered);
}

.bg-betslip-event-header:active {
  background-color: var(--background-betslip-primary-pressed);
}

/* BETSLIP BET REWARDS BANNER
 * ---------------------------------------------------- */
.bg-betslip-bet-rewards-banner {
  border: 1px solid var(--background-betslip-secondary);
  background-color: var(--background-betslip-secondary);
}

.bg-betslip-bet-rewards-banner-applied {
  border: 1px solid var(--border-success);
  background-image: radial-gradient(
    circle at 0px 0px,
    var(--background-success-40),
    var(--background-success-8) 111px 51px
  );
}
